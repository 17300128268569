import { Space, Typography } from 'antd';
import Lottie from 'lottie-react';
import React from 'react';
import noConnectionLottie from '../assets/lotties/no-connection.json';

const NoConnectionString: React.FC = () => {
  return (
    <Space direction='vertical' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Lottie animationData={noConnectionLottie} autoPlay loop style={{ width: 250 }} />
      <Typography.Title level={3}>Chave de conexão não fornecida</Typography.Title>
    </Space>
  );
};

export default NoConnectionString;
