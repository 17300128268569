import { Coordinates } from "../types/Coordinates";

export const getGeocodeByCoordinates = (latLng: Coordinates): Promise<google.maps.GeocoderResult[]> => {
    const geocoder = new window.google.maps.Geocoder();
    const { GeocoderStatus } = window.google.maps;

    return new Promise((resolve, reject) => {
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (results && status === GeocoderStatus.OK) {
                return resolve(results);
            }
            return reject(status);
        });
    });
};

export const getCoordinatess = (result: google.maps.GeocoderResult): Promise<Coordinates> =>
    new Promise((resolve, reject) => {
        const latLng = {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
        };
        return resolve(latLng);
    });