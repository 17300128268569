const sum = (cpf: string, weight: number) => {
  return Array.from(Array(weight + 1).keys()).reduce(
    (acc, curr) => acc + parseInt(cpf.substring(curr - 1, curr)) * (weight + 2 - curr),
  );
};

const check = (sumResult: number, checkDigit: number) => {
  const mod = (sumResult * 10) % 11;

  return (mod >= 10 ? 0 : mod) === checkDigit;
};

const validateCpfAlgorithm = (strCPF: string) => {
  if (!/^\d+$/.test(strCPF)) return false; // Check if has only numbers
  if (!/\d{11}/.test(strCPF)) return false; // Check if has 11 digits
  if (/^(.)\1+$/.test(strCPF)) return false; // Check if numbers are repeated

  const firstDigitValid = check(sum(strCPF, 9), parseInt(strCPF.substring(9, 10)));
  const secondDigitValid = check(sum(strCPF, 10), parseInt(strCPF.substring(10, 11)));

  return firstDigitValid && secondDigitValid;
};

export default validateCpfAlgorithm;