import { useEffect, useState } from 'react';
import Config from '../config.interace';
import getQueryParams from '../utils/common/getQueryParams';

export default function useConfig(): Config {
  const [config, setConfig] = useState({});

  useEffect(() => {
    setConfig(getQueryParams());
  }, []);

  return config;
}
