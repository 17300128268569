import { AddressType } from "../types/GoogleAddressProps";

export const parseGoogleAddressType = (type: keyof AddressType) => {
    const googleAddressType = {
      street_number: 'streetNumber',
      route: 'street',
      sublocality: 'neighborhood',
      sublocality_level_1: 'neighborhood',
      political: 'neighborhood',
      locality: 'city',
      administrative_area_level_2: 'city',
      administrative_area_level_1: 'state',
      country: 'country',
      postal_code: 'postalCode',
    };
  
    return googleAddressType[type];
  }