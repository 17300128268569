import { UploadFile } from "antd";
import axios from "axios";

import { GetSignedUrlProps } from "../types/GetSignedS3Url";
import { getSignedUrl } from "./api";
import { notificationError } from "../common/notificationError";

interface UploadFileToS3 extends GetSignedUrlProps {
    file: UploadFile;
};

export const uploadFileToS3 = async (props: UploadFileToS3) => {
    const { file, ...params } = props;
    const { signedUrl } = await getSignedUrl(params);

    try {
        file.url = encodeURI(signedUrl.url);
        await axios.put(signedUrl.signedRequest, file, {
            headers: {
                'Content-Type': file.type!,
            },
        });
        return file;
    } catch (err) {
        const description = 'Erro ao fazer o upload do arquivo.';
        notificationError(description, err);
    }
};