export const geocodeByAddress = (address: string): Promise<google.maps.GeocoderResult[]> => {
  const geocoder = new window.google.maps.Geocoder();
  const { GeocoderStatus } = window.google.maps;

  return new Promise((resolve, reject) => {
      geocoder.geocode(
          { address },
          (results, status) => {
              if (results && status === GeocoderStatus.OK) {
                  return resolve(results);
              }
              return reject(status);
          });
  });
};