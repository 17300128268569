export const filterNormalizedWithoutAccentToSelectComponent = (input: string, option: any) => {
    const value = option.label ?? option.title ?? option.children ?? '';
    return (
        value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .indexOf(
                input
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
            ) >= 0
    );
};