import { UploadFile } from "antd/lib/upload/interface";
import { Headers } from "../types/Headers";
import { AttachmentProps, IUploadRequestOption } from "../types/UploadRequestOption";
import { uploadFileToS3 } from "./aws";

const ONE_MB = 1024;

export const isValidFileSize = (file: UploadFile) => {
    const isGreaterThanTwoMB = (file.size! / ONE_MB) / ONE_MB > 2;
    return { isGreaterThanTwoMB };
};

export const handleAttachmentUploadFile = (fileList: Array<UploadFile>) => {
    const mapFileList = fileList.map<AttachmentProps>((file) => ({
        link: file.url!,
        name: file.name,
        size: file.size!,
        requestType: 'event',
    }));
    return { mapFileList };
};

export const uploadAttachment = async (
    request: IUploadRequestOption<UploadFile>,
    headers: Headers,
) => {
    const { file } = request;
    const { isGreaterThanTwoMB } = isValidFileSize(file);
    if (isGreaterThanTwoMB) return;

    const fileName = `${new Date().getTime()}_$colab_file%_${encodeURIComponent(file.name)}`;
    const bucket = process.env.REACT_APP_S3_FILES_BUCKET!;
    const uploadedFile = await uploadFileToS3({
        file,
        bucket,
        fileName,
        fileType: file.type!,
        headers,
    });
    request.onSuccess(undefined, uploadedFile);
};