import { InfoCircleOutlined } from "@ant-design/icons";
import { Divider, Tooltip, Typography } from "antd";
import styled from "styled-components";

type Props = {
  title: string;
  tooltip?: string;
};

export const SectionTitle = ({ title, tooltip }: Props) => (
  <>
    <Wrapper>
      <Typography.Title level={4} style={{ marginBottom: 0 }}>
        {title}
      </Typography.Title>
      {tooltip && (
        <Tooltip placement="right" title={tooltip}>
          <Icon />
        </Tooltip>
      )}
    </Wrapper>
    <Divider style={{ marginTop: 16 }} />
  </>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(InfoCircleOutlined)`
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;
