import { RequestType } from "../types/IPostCategory";
import { Headers } from "../types/Headers";
import { apiv4 } from "./axios";
import { City } from "../types/CityStateCountry";
import { Coordinates } from "../types/Coordinates";
import { GetSignedUrlProps } from "../types/GetSignedS3Url";

type SignedUrl = {
  signedRequest: string;
  url: string;
};

export const postCategories = async (decryptedHeaders: Headers) => {
  const { data } = await apiv4.get<RequestType>("/post-categories", {
    params: {
      where: JSON.stringify({}),
      page: 1,
      pageSize: 10,
      paginate: false,
      withDeleted: false,
    },
    headers: decryptedHeaders,
  });

  return { entityCategories: data.data };
};

export const getCitizenCityPosition = async (headers: Headers) => {
  const [{ data: cities }, { data: coordinates }] = await Promise.all([
    apiv4.get<Array<City>>('/cities/admin-user-cities', { headers }),
    apiv4.get<Coordinates>('/cities/coordinates', { headers }),
  ]);

  const [city] = cities;
  return { city, coordinates, cities };
};

export const getSignedUrl = async (param: GetSignedUrlProps) => {
  const { headers, ...params } = param;
  const { data } = await apiv4.get<SignedUrl>('/integration/aws/s3-signed-url', {
    params,
    headers,
  });

  return { signedUrl: data };
};