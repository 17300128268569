import { notification } from "antd";
import axios from "axios";
import { ReactNode } from "react";

export const notificationError = (description?: ReactNode, error?: unknown) => {
    if (axios.isAxiosError(error)) return notification.error({
        message: 'Oooops...',
        description,
    });

    notification.error({
        message: 'Ooooops...',
        description: description ?? 'Não foi possível executar a ação solicitada. Tente novamente mais tarde.'
    });
};