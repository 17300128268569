import crypto from 'crypto';

export const decrypt = <T = unknown>(ciphertext: string): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    try {
      const publicKey = Buffer.from(process.env.REACT_APP_RSA_PUBLIC_KEY as string, 'base64');

      const decryptedToken = crypto.publicDecrypt(publicKey, Buffer.from(ciphertext, 'base64'));

      resolve(JSON.parse(decryptedToken.toString('utf-8')));
    } catch (error) {
      console.log('Erro durante a descriptografia:', error);
      reject(error);
    }
  });
};
