import styled from 'styled-components'

interface Props {
    config: any;
}

const AppStyles = styled.div<Props>`

background-color: ${p => `#${p.config.backgroundColor}`};
padding: ${p => p.config.padding || 15}px;

.flex-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

`

export default AppStyles

export const GoogleMapsWrapperRender = styled.div`
    height: 300px;
    padding: 4px 0;
`;
