/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckCircleTwoTone,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tooltip,
  Upload,
  message,
  notification,
} from "antd";
import ImgCrop from "antd-img-crop";
import { UploadFile } from "antd/lib/upload/interface";
import axios from "axios";
import { changeAntdTheme } from "dynamic-antd-theme";
import GoogleMapReact from 'google-map-react';
import { useCallback, useEffect, useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import "./App.css";
import AppStyles from "./App.styles";
import { GoogleMapsPin } from "./components/GoogleMapPin";
import { SectionTitle } from "./components/SectionTitle";
import { ECategoryVisibility } from "./enums/EEventVisibility";
import useConfig from "./hooks/useConfig";
import { geocodeByAddress } from "./utils/common/GeocodeByAddress";
import { decrypt } from "./utils/common/decrypt";
import { getGeocodeByCoordinates } from "./utils/common/getGoogleMapsGeocode";
import { notificationError } from "./utils/common/notificationError";
import { parseGoogleAddressType } from "./utils/common/parseGoogleAddressType";
import validateCpfAlgorithm from "./utils/common/validateCpfAlgorithm";
import { filterNormalizedWithoutAccentToSelectComponent } from "./utils/form/filterOption";
import { getCitizenCityPosition, postCategories } from "./utils/services/api";
import { api } from "./utils/services/axios";
import {
  createNewColabUser,
  createNewPost,
  fetchUserByCpf,
} from "./utils/services/fetch";
import { MapsAutoComplete, MapsAutoCompleteResponse, MapsSessionToken } from "./utils/services/google";
import { handleAttachmentUploadFile, isValidFileSize, uploadAttachment } from "./utils/services/uploadFile";
import { CategoryVisibility } from "./utils/types/CategoryVisibility";
import { City } from "./utils/types/CityStateCountry";
import { ColabUser } from "./utils/types/ColabUser";
import { Coordinates } from "./utils/types/Coordinates";
import { FormValues } from "./utils/types/FormValues";
import { AddressType, ParsedGoogleAddress } from "./utils/types/GoogleAddressProps";
import { Headers } from './utils/types/Headers';
import { IPostCategory } from "./utils/types/IPostCategory";
import { DefaultOptionType } from "./utils/types/Select";
import { IUploadRequestOption } from "./utils/types/UploadRequestOption";
interface IDefaultOptionType extends Omit<DefaultOptionType, "children"> {
  label: string;
  value: number;
  postVisibility: ECategoryVisibility;
}

/*
Config Parameters:
{
  title: title of header
  description: additional info in header
  padding: the padding of iframe
  primaryColor: the color of contextual brand
  backgroundColor: the background color of iframe
  identificationTitle: text from title identification section
  identificationTooltip: text from title identification tooltip
  categoryTitle: text from title category section
  categoryTooltip: text from category tooltip
  postTitle: text from title post section
  postTooltip: text from post tooltip
  imageTitle: text from title image section
  imageTooltip: text from image tooltip
  postButtonText: text from post button
  connectionString: AES encrypted headers
}

*/

function App() {
  const config = useConfig();
  const [form] = Form.useForm();
  const [categories, setCategories] = useState<IPostCategory[] | undefined>([]);
  const [fileList, setFileList] = useState([]);
  const [fileUrlList, setFileUrlList] = useState([]);
  const [error, setError] = useState(false);
  const [isFetchingByCpf, setIsFetchingByCpf] = useState(false);
  const [creatingPost, setCreatingPost] = useState(false);
  const [colabUser, setColabUser] = useState<ColabUser | undefined>(undefined);
  const [userHasTypedCpf, setUserHasTypedCpf] = useState(false);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [attachmentFileList, setAttachmentFileList] = useState<UploadFile[]>([]);
  const [mapsAutoCompleteService, setMapsAutoCompleteService] = useState<MapsAutoComplete>();
  const [googleSessionToken, setGoogleSessionToken] = useState<MapsSessionToken>();
  const [googlePlacePredictions, setGooglePlacePredictions] = useState<MapsAutoCompleteResponse>();

  const [addressValidator, setAddressValidator] = useState({
    validator: (rule: any, value: any, callback: () => any) => callback(),
  });

  usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    sessionToken: true,
    language: "pt-BR",
    libraries: ["places"],
  });

  const mapsService = useCallback(() => {
    const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    const autoCompleteService = new window.google.maps.places.AutocompleteService();

    setGoogleSessionToken(sessionToken);
    setMapsAutoCompleteService(autoCompleteService);
  }, [window?.google?.maps?.places]);

  useEffect(() => {
    if (window?.google?.maps?.places) mapsService();
  }, [mapsService]);

  const [postVisibilityState, setPostVisibilityState] =
    useState<CategoryVisibility>({
      optional: ECategoryVisibility.OPTIONAL,
    });

  const isAnonymousCitizen = Form.useWatch("isAnonymousCitizen", form);
  const decryptedHeaders = useRef<Headers>({} as Headers);
  const cityzenCity = useRef<City>();
  const citiesRef = useRef<Array<City>>([]);

  const themeColor = config.primaryColor
    ? `#${config.primaryColor}`
    : process.env.REACT_APP_DEFAULT_COLOR;

  changeAntdTheme(themeColor);

  const getCategories = useCallback(async () => {
    try {
      const { entityCategories } = await postCategories(decryptedHeaders.current);

      setCategories(entityCategories);
      setError(false);
    } catch (err: any) {
      console.log("---------");
      console.log(error);
      console.log("---------");
      setError(true);
    }
  }, []);

  const getCitizenCoordinates = useCallback(async () => {
    const {
      city,
      coordinates,
      cities,
    } = await getCitizenCityPosition(decryptedHeaders.current);
    citiesRef.current = cities;
    cityzenCity.current = city;
    setCoordinates({ lat: coordinates.lat, lng: coordinates.lng });
  }, [getCitizenCityPosition]);

  const init = async () => {
  if (config?.connectionString) {
    decryptedHeaders.current = await decrypt(config.connectionString) as unknown as Headers;
    getCategories();
    getCitizenCoordinates();
  }};

  useEffect(() => {
    init();
  },[config, getCitizenCoordinates, getCategories])

  const handleCategoryChange = (category: Partial<IDefaultOptionType>) => {
    setPostVisibilityState({
      [`${category.postVisibility}`]: category.postVisibility,
    });

    switch (category.postVisibility) {
      case ECategoryVisibility.CONFIDENTIAL:
        form.setFieldsValue({
          isAnonymousCitizen: false,
          isAnonymous: true,
        });
        return;
      case ECategoryVisibility.OPTIONAL:
      case ECategoryVisibility.PUBLIC:
        form.setFieldsValue({
          isAnonymousCitizen: false,
          isAnonymous: false,
        });
        return;
      case ECategoryVisibility.ANONYMOUS:
        form.setFieldsValue({
          isAnonymousCitizen: true,
          isAnonymous: true,
        });
    }
  };

  const createFullAddress = (address_components: google.maps.GeocoderAddressComponent[]) => {
    const fullAddress = Object.fromEntries(address_components.map((components) => {
      const [type] = components.types.map((type) => parseGoogleAddressType(type as keyof AddressType));
      return [
        type, {
          name: components.long_name,
        }.name,
      ]
    })) as ParsedGoogleAddress;

    return { fullAddress };
  };

  const validateServiceArea = ({
    address_components,
    formatted_address
  }: Pick<google.maps.GeocoderResult, 'address_components' | 'formatted_address'>
  ) => {
    const { fullAddress } = createFullAddress(address_components);

    const validator = () => {
      const isValidFullAddress =
        !fullAddress?.city
        || !fullAddress?.state
        || !fullAddress?.country
        || !fullAddress?.street;

      if (isValidFullAddress)
        return Promise.reject('O endereço não possui todas as informações necessárias. Escolha outro.');

      const cities = citiesRef.current;
      const availableCities = cities.map((city) => city.name);
      const availableStates = cities.map((city) => city.state?.name);
      const availableCountries = cities.map((city) => city.state?.country?.name);

      const isValidServiceArea =
        availableCities.includes(fullAddress?.city!) &&
        availableStates.includes(fullAddress?.state!) &&
        availableCountries.includes(fullAddress?.country!);

      if (!isValidServiceArea) return Promise.reject('Endereço está fora da área de atendimento');

      const optionalCharacters = RegExp(/^.+(\s*)\d*(?:\s-\s.+)?$/g).test(formatted_address);
      if (formatted_address && !optionalCharacters) return Promise.reject('Endereço não existe ou está incorreto.');

      return Promise.resolve();
    }
    setAddressValidator({ validator });
  };

  const searchLocationDataByCoordinates = async ({ lat, lng }: Coordinates) => {
    const [{ formatted_address, address_components }] = await getGeocodeByCoordinates({ lat, lng });

    setCoordinates({ lat, lng });
    form.setFieldValue('fullAddress', formatted_address);
    validateServiceArea({ address_components, formatted_address });
    await form.validateFields(['fullAddress']);
  };

  const handleSelectAddress = async (value: string) => {
    const [geoCode] = await geocodeByAddress(value);
    const { formatted_address, geometry } = geoCode;
    setCoordinates({ lat: geometry.location.lat(), lng: geometry.location.lng() });
    form.setFieldValue('fullAddress', formatted_address);
    validateServiceArea(geoCode);
    await form.validateFields(['fullAddress']);
  };

  const handleFetchUserByCpf = async (cpf: number) => {
    setIsFetchingByCpf(true);
    const user = await fetchUserByCpf(cpf, decryptedHeaders.current);

    !!user ? setColabUser(user) : setColabUser(undefined);

    setIsFetchingByCpf(false);
    setUserHasTypedCpf(true);
  };

  const combineDescriptionRef = () => {
    const referencePoint = form.getFieldValue("reference");
    return `${form.getFieldValue("description")}${referencePoint ? `\n\nPonto de Referência: ${referencePoint}` : ""
      }`;
  };

  const onFinish = async (formValues: FormValues) => {
    if (fileUrlList.length === 0) {
      notification.error({
        message: "Oooops...",
        description: "Sua demanda precisa ter pelo menos uma imagem",
      });
      return;
    }
    setCreatingPost(true);

    const post = {
      description: combineDescriptionRef(),
      address: formValues.fullAddress,
      fullAddress: formValues.fullAddress,
      lat: coordinates.lat,
      lng: coordinates.lng,
      categoryId: formValues.category,
      category:
        categories?.find((c) => c.id === formValues.category)?.name ?? "",
      anonymousCitizen: isAnonymousCitizen,
      isAnonymous: formValues.isAnonymous,
      pictureUrl: fileUrlList,
      attachments: formValues.attachments,
      reference: formValues.reference,
      origin: 25,
    };

    const user = {
      name: formValues.userName,
      email: formValues.email,
      cpf: formValues.cpf,
      cityId: cityzenCity.current?.id,
    };

    const userPosting = Boolean(colabUser)
      ? colabUser
      : await createNewColabUser(user, decryptedHeaders.current);
    await createNewPost(
      {
        ...post,
        colabUserId: userPosting?.id!,
        city: cityzenCity.current?.name!,
        state: cityzenCity.current?.state.name!,
        country: cityzenCity.current?.state.country.name!,
      },
      decryptedHeaders.current
    );

    setCreatingPost(false);
    form.resetFields();
    setFileList([]);
    setAttachmentFileList([]);
  };

  const onChangeFileUpload = ({ fileList: newFileList, file }: any) => {
    const { isGreaterThanTwoMB } = isValidFileSize(file);
    if (isGreaterThanTwoMB) {
      return false;
    }

    setFileList(newFileList);
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadFile = async (clbk: any) => {
    const fileName = `iframe_integration_event_${Date.now()}.jpg`;

    const signedUpload = await api.get(
      `v2/aws/s3/signed-url?fileName=${fileName}&fileType=image%2Fjpg`,
      {
        headers: decryptedHeaders.current,
      }
    );

    axios
      .put(signedUpload.data["signedRequest"], clbk.file, {
        headers: {
          "Content-Type": "image/jpg",
        },
      })
      .then((response) => {
        clbk.onSuccess(response, clbk.file);
        setFileUrlList([...fileUrlList, signedUpload.data["url"]]);
      })
      .catch(() => {
        clbk.onError({ body: "deu ruim" });
      });
  };

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg";
    if (!isJpgOrPng) {
      message.error("As imagens devem ser JPG ou JPEG");
    }

    const { isGreaterThanTwoMB } = isValidFileSize(file);

    if (isGreaterThanTwoMB) {
      notificationError('Seu arquivo tem que ter menos de 2MB!');
      return !isGreaterThanTwoMB;
    }

    return isJpgOrPng && !isGreaterThanTwoMB;
  }

  const handleSearchAddress = async (query: string) => {
    if (!!mapsAutoCompleteService) {
      const autoCompleteResponse = await mapsAutoCompleteService.getPlacePredictions({
        input: query,
        componentRestrictions: { country: "br" },
        types: ["address"],
        sessionToken: googleSessionToken,
      });
      setGooglePlacePredictions(autoCompleteResponse);
    }
  };

  return (
    <AppStyles config={config}>
      {!error && (
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={30}>
            <Col xs={24}>
              <h1>{config.title || "Colabore com a sua cidade"}</h1>
              <p>
                {config.description ||
                  "Registre ocorrências e sugestões para que a prefeitura trabalhe na solução dos problemas da sua cidade"}
              </p>
            </Col>
          </Row>

          <Row gutter={30}>
            <Col xs={24}>
              <SectionTitle
                title={config.categoryTitle || "Qual a categoria?"}
                tooltip={
                  config.categoryTooltip ||
                  "Escolha a categoria da sua ocorrência"
                }
              />
            </Col>
            <Col xs={24}>
              <Form.Item
                name="category"
                rules={[
                  {
                    required: true,
                    message:
                      "Precisamos saber em qual categoria sua demanda se encaixa",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Digite ou escolha uma categoria"
                  onChange={(_, option) => {
                    handleCategoryChange(option as Partial<IDefaultOptionType>);
                  }}
                  filterOption={filterNormalizedWithoutAccentToSelectComponent}
                  options={categories
                    ?.sort((categoryA, categoryB) =>
                      categoryA.name.localeCompare(categoryB.name, "pt-BR")
                    )
                    .map((category) => ({
                      value: category.id,
                      label: category.name,
                      postVisibility: category.postVisibility,
                    }))}
                />
              </Form.Item>
            </Col>
          </Row>
          {!postVisibilityState.anonymous ? (
            <>
              <Col xs={24}>
                <SectionTitle
                  title={config.identificationTitle || "Identificação"}
                  tooltip={
                    config.identificationTooltip ||
                    "Digite seu CPF para se identificar"
                  }
                />
              </Col>
              <Col xs={8}>
                <Form.Item
                  name="isAnonymousCitizen"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox
                    disabled={
                      !!postVisibilityState.public ||
                      !!postVisibilityState.confidential ||
                      !!postVisibilityState.optional
                    }
                  >
                    <Tooltip
                      title={
                        !!postVisibilityState.public ||
                          !!postVisibilityState.confidential ||
                          !!postVisibilityState.optional
                          ? "A categoria selecionada não permite que a demanda seja anônima"
                          : null
                      }
                    >
                      Não quero me identificar
                    </Tooltip>
                  </Checkbox>
                </Form.Item>
                {!isAnonymousCitizen && (
                  <>
                    <Form.Item
                      label="CPF"
                      name="cpf"
                      extra={"Precisamos apenas dos numeros"}
                      rules={[
                        {
                          required: true,
                          message: "Precisamos do seu cpf",
                        },
                        {
                          min: 11,
                          validateTrigger: "onSubmit",
                          message: "Não se esqueça de inserir um cpf válido",
                        },
                        {
                          max: 11,
                          validateTrigger: "onSubmit",
                          message: "Não se esqueça de inserir um cpf válido",
                        },
                        () => ({
                          validator(rule, value) {
                            if (value && value.length === 11) {
                              const cpfValidation = validateCpfAlgorithm(value);
                              if (!cpfValidation) {
                                return Promise.reject(
                                  new Error(
                                    "Não se esqueça de inserir um cpf válido"
                                  )
                                );
                              }
                              handleFetchUserByCpf(value);
                            } else {
                              setIsFetchingByCpf(false);
                              setUserHasTypedCpf(false);
                              setColabUser(undefined);
                              return;
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        disabled={isFetchingByCpf}
                        suffix={
                          isFetchingByCpf ? (
                            <LoadingOutlined />
                          ) : Boolean(colabUser) ? (
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                          ) : null
                        }
                        maxLength={11}
                        placeholder={"Insira aqui seu cpf"}
                      />
                    </Form.Item>
                  </>
                )}
              </Col>
              {userHasTypedCpf && !isAnonymousCitizen && !Boolean(colabUser) && (
                <>
                  <Col xs={8}>
                    <Form.Item
                      label="Nome"
                      name="userName"
                      rules={[
                        {
                          required: true,
                          message: "Precisamos do seu nome",
                        },
                      ]}
                    >
                      <Input
                        disabled={Boolean(colabUser)}
                        placeholder={"Insira aqui seu nome"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={8}>
                    <Form.Item
                      label="E-mail"
                      name="email"
                      extra="Ao inserir seu e-mail você receberá notificações da atualização do seu pedido."
                      rules={[
                        {
                          type: "email",
                          validateTrigger: "onSubmit",
                          message: "Esse valor não é um email válido",
                        },
                      ]}
                    >
                      <Input
                        disabled={Boolean(colabUser)}
                        type="email"
                        placeholder={"Insira aqui seu email"}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              {!postVisibilityState.public && (
                <>
                  <SectionTitle title={"Configuracões de privacidade"} />
                  <Form.Item
                    name="isAnonymous"
                    valuePropName="checked"
                    initialValue={false}
                    extra={
                      "Ao tornar a publicação sigilosa, apenas alguns responsáveis pelo tratamento da demanda poderão ver quem foi o solicitante"
                    }
                  >
                    <Checkbox disabled={!!postVisibilityState.confidential}>
                      <Tooltip
                        placement="right"
                        title={
                          !!postVisibilityState.confidential
                            ? "A categoria selecionada requer a publicação seja confidencial"
                            : null
                        }
                      >
                        Tornar publicação sigilosa
                      </Tooltip>
                    </Checkbox>
                  </Form.Item>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <Row gutter={30}>
            <Col xs={24}>
              <SectionTitle
                title={config.postTitle || "Sua demanda"}
                tooltip={"Informações referentes ao detalhe de sua demanda"}
              />
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Descrição"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Conte-nos mais sobre sua demanda",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder={"Conte-nos mais sobre sua demanda"}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Local da ocorrência"
                name={'fullAddress'}
                rules={[
                  {
                    required: true,
                    message: "Toda demanda precisa de um endereço",
                  },
                  {
                    validator: addressValidator.validator,
                  }
                ]}
              >
                <AutoComplete
                  placeholder={"Digite o endereço da ocorrência"}
                  onSearch={handleSearchAddress}
                  onSelect={handleSelectAddress}
                  onBlur={async () => {
                    if (googlePlacePredictions?.predictions?.length === 1) {
                      const [place] = googlePlacePredictions?.predictions;
                      await handleSelectAddress(place.description);
                    }
                  }}
                  showSearch
                  filterOption={false}
                  notFoundContent={
                    !googlePlacePredictions?.predictions?.length
                      ? <Spin size="small" />
                      : null
                  }
                >
                  {googlePlacePredictions?.predictions?.map((place: any) => (
                    <Select.Option
                      key={place.place_id}
                      value={place.description}
                    >
                      {place.description}
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Ponto de referência"
                name="reference"
              >
                <Input
                  type="text"
                  placeholder={"Informe um ponto de referência"}
                />
              </Form.Item>
            </Col>
            <div style={{ height: "500px", width: "100%" }}>
              <GoogleMapReact
                onClick={({ lat, lng }) => {
                  searchLocationDataByCoordinates({ lat, lng });
                }}
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_API_KEY as string,
                  libraries: ["places"],
                  language: "pt-BR",
                }}
                center={coordinates}
                defaultZoom={14}
                options={{
                  mapTypeControl: true,
                  disableDefaultUI: true,
                  disableDoubleClickZoom: true,
                  keyboardShortcuts: false,
                }}
                yesIWantToUseGoogleMapApiInternals
              >
                <GoogleMapsPin lat={coordinates.lat} lng={coordinates.lng} />
              </GoogleMapReact>
            </div>

            <Col xs={24} className={"mb-30"}>
              <SectionTitle
                title={config.imageTitle || "Imagens"}
                tooltip={
                  config.imageTooltip ||
                  "Insira até 3 imagens ilustrando sua demanda"
                }
              />
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "A demanda precisa de pelo menos uma imagem",
                  },
                ]}
              >
                <ImgCrop
                  rotate
                  beforeCrop={beforeUpload}
                  modalTitle={"Ajuste sua imagem"}
                >
                  <Upload
                    maxCount={3}
                    beforeUpload={beforeUpload}
                    customRequest={uploadFile}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={onPreview}
                    onChange={onChangeFileUpload}
                    accept={".jpg,.jpeg"}
                  >
                    {fileList.length < 3 && (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </Col>
            <Col xs={24} className={"mb-30"}>
              <SectionTitle
                title="Anexos"
                tooltip="Insira até 3 arquivos na sua demanda"
              />
              <Form.Item
                name="attachments"
              >
                <Upload
                  listType="picture-card"
                  maxCount={3}
                  customRequest={async (request) => {
                    const req = request as unknown as IUploadRequestOption;
                    await uploadAttachment(req, decryptedHeaders.current);
                  }}
                  fileList={attachmentFileList}
                  onChange={({ fileList, file }) => {
                    const { isGreaterThanTwoMB } = isValidFileSize(file);
                    if (isGreaterThanTwoMB) return notificationError('O arquivo não pode ter mais de 2MB.');

                    const { mapFileList } = handleAttachmentUploadFile(fileList);
                    setAttachmentFileList(fileList);
                    form.setFieldValue('attachments', mapFileList);
                  }}
                  showUploadList={{
                    showPreviewIcon: true,
                    showDownloadIcon: true,
                  }}
                >
                  {attachmentFileList.length < 3 && '+ Upload'}
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Item>
                <Button
                  loading={creatingPost}
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  shape="round"
                >
                  {config.postButtonText || "Publicar"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </AppStyles>
  );
}

export default App;
