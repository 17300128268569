import { EnvironmentFilled } from '@ant-design/icons';
import { PinWrapper } from './styles';

type Props = {
    lat: number;
    lng: number;
};

export const GoogleMapsPin = ({ lat, lng }: Props) => {
    return (
        <PinWrapper lat={lat} lng={lng}>
            <EnvironmentFilled
                style={{
                    fontSize: 30,
                    position: 'absolute',
                    transform: 'translate(-50%, -100%)',
                }}
            />
        </PinWrapper>
    );
};