import { notification } from "antd";
import { ColabUser } from "../types/ColabUser";
import { Headers } from "../types/Headers"
import { NewColabUser } from "../types/NewColabUser";
import { NewPost } from "../types/NewPost";
import { apiv4 } from "./axios"

export const fetchUserByCpf = async (cpf: number, headers: Headers) => {
  try {
    const {data: colabUser} = await apiv4.get('/colab-users', {params: {cpf}, headers});
    return colabUser as ColabUser;
  } catch(e) {
    notification.error({message: 'Oooops...', description: 'Algo deu errado ao procurar seu usuário'});
    console.log(e);
  }
}

export const createNewColabUser = async (newUser: NewColabUser, headers: Headers) => {
  try {
    const { data: brandNewColabUser } = await apiv4.post('/colab-users', newUser, {headers});

    return brandNewColabUser as unknown as  ColabUser;
  } catch(e) {
    notification.error({message: 'Oooops...', description: 'Algo deu errado ao criar sua demanda'});
    console.log(e);
  }
}

export const createNewPost = async (postInfo: NewPost, headers: Headers) => {
  try {
    const newPost = await apiv4.post('/posts', postInfo, {headers});
    notification.success({message: 'Ok', description: 'Sua demanda foi criada com sucesso!!!'})
    return newPost
  } catch (e) {
    notification.error({message: 'Oooops...', description: 'Algo deu errado ao criar sua demanda'});
    console.log(e);
  }
}